import React from 'react';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function NotFoundPage() {
	const navigate = useNavigate();

	const onToHome = () => {
		navigate(`/`);
	};

	return (
		<>
			<div
				style={{
					position: `absolute`,
					left: `50%`,
					top: `50%`,
					transform: `translate(-50%, -50%)`,
					width: `90%`,
				}}
			>
				<Typography variant="h1" align="center">
					Здесь ничего нет
				</Typography>
				<Typography variant="h3" align="center">
					Страница, которую ты пытаешься открыть, не существует
				</Typography>
				<Button
					style={{
						left: `50%`,
						top: `50%`,
						transform: `translate(-50%, -50%)`,
						width: `150px`,
						marginTop: `50px`,
					}}
					variant="contained"
					size="large"
					onClick={onToHome}
				>
					На главную
				</Button>
			</div>
		</>
	);
}
