import { Box, Input, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useContext, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Rest } from '../utils/rest';
import { UrlContext } from '../context/urlContext';

export default function Login() {
	const navigate = useNavigate();
	const urls = useContext(UrlContext);

	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState<string>(``);
	const [error, setError] = useState<boolean | undefined>(false);

	const onSubmit = async () => {
		if (password === ``) return;

		setLoading(true);
		setError(false);

		const keys = Object.keys(urls);
		const res = await axios.post(`${urls[keys[0]]}/support`, { password });
		if (res.status === 200 && res.data.code === 200) {
			Cookies.set(`token`, res.data.result.token, { expires: 3, path: `` });
			navigate(`/`);
		}

		setError(true);
		setLoading(false);
	};

	return (
		<Box
			style={{
				position: `absolute`,
				left: `50%`,
				top: `50%`,
				transform: `translate(-50%, -50%)`,
				width: `500px`,
			}}
		>
			<Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
				<Typography variant="overline" style={{ fontSize: `14pt` }}>
					Login to support dashboard
				</Typography>
				<TextField 
					id="adminPassword" 
					label="Password" 
					variant="outlined" 
					onChange={(e) => setPassword(e.target.value)} 
					type="password"
					error={error}
					onKeyPress={(e) => {
						if (e.code === `Enter`) onSubmit();
					}}
					fullWidth 
				/>
				<LoadingButton loading={loading} onClick={onSubmit} variant="contained">
					Enter
				</LoadingButton>
			</Stack>
		</Box>
	);
}
