import Cookies from 'js-cookie';
import axios from 'axios';

export class Rest {
	public static async getURLs(): Promise<Urls> {
		return (await axios.get(`${window.location.origin}/url`)).data;
	}

	public static async createInstance() {
		const token = Cookies.get(`token`);
		if (!token) return null;
		return axios.create({
			headers: { 'Content-Type': `application/json`, Authorization: token },
			validateStatus: (status) => status < 500,
		});
	}
}

export interface Urls {
	[key: string]: string;
}
