import React from 'react';
import { UrlProvider } from './context/urlContext';
import Router from './routes/Router';

export default function App() {
	return (
		<UrlProvider>
			<Router />
		</UrlProvider>
	);
}
