import React, { createContext, useEffect, useState } from 'react';
import { Rest, Urls } from '../utils/rest';

export const UrlContext = createContext<Urls>({});

function UrlProvider({ children }: { children: JSX.Element }) {
	const [urls, setUrls] = useState<Urls>({});

	useEffect(() => {
		Rest.getURLs().then((_urls) => setUrls(_urls));
	}, []);

	return <UrlContext.Provider value={urls}>{children}</UrlContext.Provider>;
}

export { UrlProvider };
