import {
	Box,
	CircularProgress,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Rest } from '../utils/rest';
import { useDebounce } from 'react-use';
import { UrlContext } from '../context/urlContext';

interface User {
	email: string;
	nickname: string;
	country: string;
	platform_id: number;
	activated: boolean;
}

interface RowUser {
	email: string;
	country: string;
	id: number;
	activated: string;
	robot: string;
	arrID: number;
}

async function getRow(url: string, robot: string, id: number) {
	const userInstance = await Rest.createInstance();
	if (!userInstance) return { error: true };
	const user = await userInstance.get(url);
	if (user.status !== 200) return { error: false };
	if (user.data.code !== 200) return { error: true, code: user.data.code };

	const res: User = user.data.result;
	const row = {
		id: res.platform_id,
		email: res.email,
		activated: res.activated ? `Yes` : `No`,
		country: res.country,
		robot: robot,
		arrID: id,
	};

	return { error: false, row: row };
}

interface SearchOptions {
	searchById?: boolean;
}

export default function Main() {
	const navigate = useNavigate();
	const urls = useContext(UrlContext);

	const [loading, setLoading] = useState<Boolean>(false);
	const [users, setUsers] = useState<RowUser[]>([]);
	const [email, setEmail] = useState('');
	const [userId, setUserId] = useState('');
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');

	const searchUsers = async (options?: SearchOptions) => {
		if (options?.searchById && userId === '') return;
		if (!options && email === '') return;

		setLoading(true);
		const _users: RowUser[] = [];
		setUsers([]);
		setError(false);
		setErrorMessage(``);

		for (const key of Object.keys(urls)) {
			const val = urls[key];
			const url = new URL(`${val}/support/users?${options?.searchById ? `user_id=${userId}` : `email=${email}`}`);
			const rowRes = await getRow(url.href, key, users.length);
			if (rowRes.error && rowRes.code === 401) navigate(`/login`);
			if (!rowRes.row) continue;
			_users.push(rowRes.row);
			setUsers((users) => [...users, rowRes.row]);
		}

		if (_users.length === 0) {
			setError(true);
			setErrorMessage(`This user doesn't exist`);
		}

		setLoading(false);
	};

	useDebounce(searchUsers, 1000, [email]);
	useDebounce(() => searchUsers({ searchById: true }), 1000, [userId]);

	useEffect(() => {
		const token = Cookies.get(`token`);
		if (!token || token === ``) navigate(`/login`);
	}, []);

	return (
		<Box
			style={{
				position: `absolute`,
				left: `50%`,
				top: `50%`,
				transform: `translate(-50%, -50%)`,
				width: `650px`,
			}}
		>
			<Stack spacing={2}>
				<Stack spacing={2}>
					<TextField
						onChange={({ currentTarget }) => setEmail(currentTarget.value.trim())}
						id="userEmail"
						label="Email of user"
						variant="outlined"
						error={Boolean(email) && error}
						helperText={Boolean(email) && error && errorMessage}
						fullWidth
					/>
					<Typography>
						For BinomoBot users:
					</Typography>
					<TextField
						onChange={({ currentTarget }) => setUserId(currentTarget.value.trim())}
						id="user_id"
						label="User id"
						variant="outlined"
						error={Boolean(userId) && error}
						helperText={Boolean(userId) && error && errorMessage}
						fullWidth
					/>
					{loading ? <CircularProgress /> : <></>}
				</Stack>

				<Box style={{ height: `500px` }}>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell align="center">ID</TableCell>
									<TableCell align="center">Email</TableCell>
									<TableCell align="center">Activated</TableCell>
									<TableCell align="center">Country</TableCell>
									<TableCell align="center">Robot</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{users.map((user) => (
									<TableRow
										key={user.arrID}
										sx={{
											'&:last-child td, &:last-child th': {
												border: 0,
											},
										}}
									>
										<TableCell component="th" scope="row" align="center">
											{user.id}
										</TableCell>
										<TableCell align="center">{user.email}</TableCell>
										<TableCell align="center">{user.activated}</TableCell>
										<TableCell align="center">{user.country}</TableCell>
										<TableCell align="center">{user.robot}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Stack>
		</Box>
	);
}
